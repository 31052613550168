.footer-main{
    width: 100%;
    position: absolute;
    background: #034444;
    color: #fff;
    padding: 10px 0px 20px;
    font-size: 13px;
    line-height: 24px;
    margin-top: 40px;
}
.footer{
    width: 85%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}
.col{
    flex-basis: 25%;
    padding: 10px;
}
.copi p{
    text-align: center;
}
.col:nth-child(2),col :nth-child(3){
    flex-basis: 15%;
}
.logoss{
    width: 100px;
    margin-bottom: 20px;
}
.col h3{
    width: fit-content;
    margin-bottom: 40px;
    font-size: 20px;
    font-family: oblique;
}
.email-id{
    width: fit-content;
    border-bottom: 1px solid #ccc;
    margin: 20px 0;
}
ul li{
    list-style: none;
    margin-bottom: 12px;
}
.media img{
    width: 35px;
    margin: 5px;
    cursor: pointer;
}
.footer-main hr{
    width: 90%;
    border: 0;
    border-bottom: 1px solid #ccc;
    margin: 20px auto;
}
.copy-right{
    text-align: center;
}
/*media*/
@media(max-width:708.021px){
    .footer-main{
        margin-top: 200px;
    }
}