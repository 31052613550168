.about-bg img{
    width: 100%;
    margin-top: 67px;
   height: 85vh;
   
}
/*.about-bg h1{
    position: absolute;
    top: 450px;
    left: 500px;
    color: rgb(5, 5, 5);
    background-color: white;
}*/
.about1{
    margin-top: 10%;
    margin-bottom: 40%;
}
.about{
  position: absolute;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-color: rgb(248, 248, 248);
 
 
}
.about b{
  margin-top: 20px;
  font-size: 40px;
  font-family: Monospace;
  margin-bottom: 20px;
  border-bottom: 1px solid red;
  color: rgb(36, 94, 94);
  
}
.about p{
  max-width: 95%;
  text-align:justify;
  font-size: 20px;
  margin: 10px auto 20px;
  line-height: 1.4; 
  font-family: oblique;
}
.about h3{
  font-size: 20px;
    margin: 20px;
    line-height: 1.4;
    font-family: oblique;
    border-bottom: 1px solid rgba(0, 0, 0, 0.308);
}

 /*---media ----*/
@media(max-width:708.021px){
  .about-bg img{
    height: 40vh;
    margin-top: 55px;
  }
  .about1{
   margin-bottom: 260%
  }
  .about p{
    text-align: left;
   margin: 30px;
   line-height: normal;
  }
  .about b{
    font-size: 25px;
    font-family: serif;
    color:  rgba(194, 165, 39, 0.829);;
  }
}