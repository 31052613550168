.text-main img{
    width: 100%;
   }
   .text{
     margin-top: 10rem;
       width: 100%;
       display: flex;
       justify-content: center;
       flex-wrap: wrap;
       margin-bottom: 10rem;
     }
     .text-1{
      flex-basis: 50%;
      margin-bottom: 130px;
     }
     
     .text-2{
       margin-top: 30px;
       flex-basis: 40%;
       
     }
     .text-1 img{
       width: 90%;
       
     }
     .text-2 h3{
     text-align: center;
      font-size: 30px;
      color: rgb(36, 94, 94);
      font-family: serif;
     }
     .text-2 b{
         text-align: center;
      font-size: 30px;
      color: rgb(36, 94, 94);
      font-family: serif;
     }
     
     .text-2 p{
         margin-top: 30px;
       line-height: 1.5;
       font-family: serif;
       font-size: 18px;
       margin-left: 20px;
       text-align: justify;
       margin-right: 30px;
     }
     /*media*/
     @media(max-width:708.021px){
      .text-main img{
        margin-top: 4rem;
      }
       .text{
         flex-direction: column;
       }
       .text-main hr{
         display: none;
       }
       .text-1{
       flex-basis: 100%;
       margin: 20px;
       order: 2;
       }
       .text-2{
     flex-basis: 100%;
       }
       .text-2 h3{
         font-family: serif;
         font-size: 25px;
         color:  rgba(194, 165, 39, 0.829);
         margin-bottom: 25px;
        
       }
       .text-2 p{
        text-align: left;
       }
     }