@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  
}
body {
  background: #f5f5f5;
  width: 100%;
  height: 100vh;
  
}
a{
  text-decoration: none;
  color: inherit;
  line-height: 1;
  cursor: pointer;
  font-family: "times New Roman", Times, serif;
}
a:hover{
  color: rgba(255, 230, 0, 0.705);
  padding-bottom: 0.5rem;
  border-bottom: 2px solid white;
}
.container{
  padding-left: 10%;
  padding-right: 10%;
  
}
/*-----media query------*/
@media (max-width:1200px){
  .container{
    padding-left: 5%;
    padding-right: 5%;
  }
}
