.landing{
    margin-top: 10%;
    margin-bottom: 30%;
   
   
  }
  .landing-1{
    position: absolute;
    
    display: flex;
    flex-direction: column;
    
   
  }
  .landing-1 b{
    margin-top: 20px;
    margin-left: 20px;
    font-size: 25px;
    font-family: serif;
    margin-bottom: 20px;
    color: rgb(36, 94, 94);
    
  }
  .landing-1 p{
   
    max-width: 95%;
    text-align: justify;
    font-size: 20px;
    margin: 10px auto 20px;
    line-height: 1.4; 
    font-family: oblique;
  }
  
  
  /*---media ----*/
  @media(max-width:708.021px){
    .landing{
     
      margin-bottom: 110%;
    }
    .landing-1 p{
      font-size:20px;
      margin: 30px;
      text-align: left;
      line-height: normal;
    }
  }
  