.proccess-main{
    margin-bottom: 50px;
    margin-top: 150px;

  }
  .proccess{
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .proccess-1{
     flex-basis: 50%;
     margin-bottom: 130px;
    }
    
    .proccess-2{
      margin-top: 30px;
      flex-basis: 40%;
    }
    .proccess-1 img{
      width: 90%;
      
    }
    .proccess-2 h3{
    text-align: center;
     font-size: 30px;
     color: rgb(36, 94, 94);
     font-family: serif;
    }
    
    
    .proccess-2 p{
        margin-top: 30px;
      line-height: 1.5;
      font-family: serif;
      font-size: 18px;
      margin-left: 20px;
      text-align: justify;
      margin-right: 30px;
    }
    /*media*/
    @media(max-width:708.021px){
      .proccess{
        flex-direction: column;
      }
      .proccess-main hr{
        display: none;
      }
      .proccess-1{
      flex-basis: 100%;
      margin: 20px;
      order: 2;
      }
      .proccess-2{
    flex-basis: 100%;
      }
      .proccess-2 p{
        text-align: left;
      }
      .proccess-2 h3{
        font-family: serif;
        font-size: 25px;
        color:  rgba(194, 165, 39, 0.829);
        margin-bottom: 25px;
       
      }
    }