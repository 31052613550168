.wrapper{
   
    display: flex;
    justify-content: center;
    align-items: center;
}
.auditmain{
    margin-top: 8rem;
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 10rem;
   
}
.auditimg{
    margin-right: 30px;
    flex-basis: 20%;
}
.audit{
    width: 500px;
   
}
.item{
    margin-bottom: 10px;
    padding: 10px 20px;
    background: #f0ebe1;
}
.titel h2{
    color: #85662b;
    font-family: serif;   
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.content{
    color: #050302;
    font-family: serif;
}
@media(max-width:708.021px){
    .auditmai{
        flex-direction: column;

    }
    .auditimg img{
       display: none;
    }
    .audit{
        margin-top: 2rem;
    }
    .auditmain{
        margin-bottom: 2rem;
    }
}