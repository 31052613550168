.articilebg img{
    width: 100%;
    height: 50vh;
}
.artmain {
    margin-top: 40px;
    align-items: center;
    justify-content: center;
    text-align: justify;
    margin-left: 7rem;
}
.artmain1{
    margin-top: 40px;
    align-items: center;
    justify-content: center;
    text-align: justify;
    line-height: 30px;
    margin-left: 7rem;
}
.artmain2{
    margin-top: 40px;
    align-items: center;
    justify-content: center;
    text-align: justify;
    line-height: 30px;
    margin-left: 7rem;
}
.artmain3{
    margin-top: 40px;
    align-items: center;
    justify-content: center;
    text-align: justify;
    line-height: 30px;
    margin-left: 7rem;
}
.artmain4{
    margin-top: 40px;
    align-items: center;
    justify-content: center;
    text-align: justify;
    line-height: 30px;
    margin-left: 7rem;
}

.artmain h1{
    font-family: serif;
}
.artmain h2{
font-family: sans-serif;
line-height: 80px;
}
.artmain p{
    font-family: serif;
    text-align:justify;
   
}
.artmain b{
    font-family: serif;
}
.artmain1 h2{
    font-family: sans-serif;
    line-height: 80px;
}
.artmain1 p{
    font-family: serif;
}
.artmain2 h2{
    font-family: sans-serif;
    line-height: 80px;
}
.artmain2 p{
    font-family: serif;
}
.artmain3 h2{
    font-family: sans-serif;
    line-height: 80px;
}
.artmain3 p{
    font-family: serif;
}
.artmain4 h2{
    font-family: sans-serif;
    line-height: 80px;
}
.artmain4 p{
    font-family: serif;
}
/*midia*/
@media(max-width:708.021px){
    .artmain{
    margin-left: auto
    }
    .artmain1{
        margin-left: auto
        }
        
        .artmain2{
            margin-left: auto
            }
            .artmain3{
                margin-left: auto
                }
                .artmain4{
                    margin-left: auto
                    }  
                    .artmain h2{
                        font-size:medium;
                       line-height: normal; 
                    } 
    }
