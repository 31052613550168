.banner{
    width: 98.5vw;
    height: 65vh;
    position: relative;
    background-image: url('../Assats//stranth.jpg');
    background-size: cover;
    margin-top: 6rem;
    border-radius: 5px;
}
.imgtext{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.616);
}
.imgtext h2{
    margin-top: 3rem;
    color: aliceblue;
    border-bottom: 1px solid red;
font-family: serif;
font-size: 2.5rem;
}
.imgtext p{
    color: aliceblue;
    margin: 5rem;
    font-size: 1.2rem;
    font-family: serif;
    line-height: 2rem;
}
/*---media ----*/
@media(max-width:708.021px){
    .imgtext p{
       text-align: justify;
       margin: auto;
    }
}
