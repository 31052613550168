nav{
    width: 100%;
   position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background:#034444;
    color: aliceblue;
}
nav p{
text-align: center;
font-family:  Courier New;
font-size: 30px;
}
nav>ul>li{
    display: inline-block;
    list-style: none;
    margin: 5px 20px;
    font-size: 20px;
}
.menu{
    display: none;
}
/*megha manu*/
nav ul li ul{
    position: absolute;
    display: none;
    background: #034444;
    width: 140px;
    text-align: center;
   border-radius: 2px;
   box-shadow: 1px 3px 4px cadetblue;
}
nav ul li ul li {
    font-size: 16px;
}

nav ul li:hover ul{
    display: block;
}
/*midia*/
@media(max-width:708.021px){
    nav p{
       font-size: 20px;
    }
    nav{
        padding: 15px 0;
    }
    nav>ul{
        position: fixed;
        top:0;
        right: 0;
        height: 100vh;
        background:#0a0a0ae3;
        z-index: -1;
        width: 180px;
        padding-top: 30px;
        transition: 0.5s;

    }
    nav>ul>li{
        
        top: 60px;
        display: block;
        margin: 25px 40px;
    }
    .menu{
        display: block;
        width: 20px;
        cursor: pointer;
    }
    .hide-mobile-menu{
        right: -200px;
    }
    .dark-nav{
        background: rgb(68, 55, 55);
        transition: 0.5s;
    }
    .navimg{
        display: none;
    }
    .nav ul li ul{
        width: 20px;
    }
}





