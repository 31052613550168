.card-main img{
width: 100%;
}
.card-main h1{
    position: absolute;
     top: 200px;
     left: 500px;
     font-family: Garamond, serif;
     font-size: 60px;
     color: white;
}
.card-container {
   
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
   
}

.card {
   margin-top: 7rem;
    background-color: rgb(36, 94, 94);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgb(94, 50, 36);
    overflow: hidden;
    width: 350px;
    text-align: center;
    margin-left: 20px;
}

.card img {
    width: 100%;
    height: auto;
}

.card-content {
    padding: 20px;
}

.card-content h3 {
    margin: 0 0 10px;
    color: aliceblue;
    font-family: serif;
}

.card-content p {
    color:wheat;
    font-size: 17px;
    margin: 0 0 20px;
    font-family: serif;
}

button {
    background-color: #000407;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}
/*media*/
@media(max-width:708.021px){
    .card-main img{
        margin-top: 3rem;
        width:100% ;
    }
    .card-main h1{
        left: 120px;
      top:80px;
  font-size: 24px;
    }
    .card{
margin-left: auto;
    }
}